.users .container {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
}

.users .container > div {

    margin-right: 20px;
}

.users .container > div:last-of-type {
    margin: 0;
}

/* .users .dialogButton {
    justify-content: space-between!important;
}

.users .dialogButton .flex-end {
    justify-content: flex-end!important;
} */

.users .editFields > div {
    display: block !important;
    margin-bottom: 15px;
}

.users .editFields > div > div {
    width: 100%;
}

.users .editFields .FLAG_ADMIN > label {
    font-size: 75%;
}

.users .editFields .AGENTE_COD  {
    min-width: 350px;
}
 
.users.editing-dialog svg.Mui-disabled {
    color: transparent !important;
}

.users.editing-dialog  .Mui-disabled:before {
    border-bottom: none !important;
}

.editFields > div > div > input {
    white-space:        nowrap;
    overflow:           hidden;
    text-overflow:      ellipsis;
}

.users.editing-dialog .FLAG_ATTIVO .select {
    width:              90px;
    margin-right:       15px;
}

.users.editing-dialog .FLAG_ATTIVO .Disabled {
    width:              70%;
}

.users.editing-dialog .container {
    display:            flex;
    align-items:        center;
    flex-direction:     row;
}

.users.editing-dialog .checkbox {
    margin-left:        -12px;
}

.users.editing-dialog .inputLabel {
    color:              rgb(70,70,70) !important;
}

.fixPad {
    padding: 10px 24px 0 24px!important;
}


.dianButtonLarge {
    width: 100%;
    color: #000000 !important;
    border: 1px solid #000!important;
    border-radius: 5px !important;
    text-decoration: none !important;
    padding: 7px 25px !important;
}

.dianButtonLarge:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
}