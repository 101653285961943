.popupTitle,
.popupSubTitle {
    padding-bottom: 5px !important;
}

.noPadding {
    padding: 0 24px;
}

.popupMoreActions {
    color: var(--color-dark)!important;
} 

.popupConfirm {
    color: var(--color-green)!important;
}

.popupRemove {
    color: var(--color-red)!important;
}

.popupClear {
    color: var(--color-annultext)!important
}

.popupAnnul {
    color: var(--color-annultext)!important;
}

.popupConfirm:hover,
.popupRemove:hover,
.popupClear:hover,
.popupAnnul:hover {
    background-color: var(--color-ultralightgrey)!important;
}


.myWrapperContent {
    padding: 0px 24px!important;
}