
.errorPage .app-login-container{
    
    border-radius: 5px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 2px 3px 0 rgb(0 0 0 / 14%), 0 2px 6px 0 rgb(0 0 0 / 20%);
}

.errorPage .card-wrapper {
    padding:                15px 35px 35px;
    max-width:              400px;
    width:                  400px;
    display:                flex;
    justify-content:        center;
    align-items:            center;
}
 
.errorPage .card-wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorPage .wrap-row {
    display:            flex;
    justify-content: center;
    align-items: center;
}

.errorPage .descErr {
    padding-bottom: 25px;
    text-align: center;
}

.errorPage .backButton {
    color:            #000000 !important;
    border:             1px solid #000;
    border-radius:      5px !important;
    text-decoration:    none !important;
    padding:            7px 25px !important;
    height:             42px;
}

.localsole .errorPage .backButton:hover,
.prodsole  .errorPage .backButton:hover {
    background-color:   var(--color-button);
    border-color:       var(--color-dark);
}

.local    .errorPage .backButton:hover,
.proddisc .errorPage .backButton:hover {
    background-color:   var(--color-button);
    border-color:       var(--color-dark);
    color:              var(--color-dark)!important;
}


.errorPage .title-error {
    font-size: 100px;
    line-height: 0;
    color: rgba(15, 15, 15, 0.15);
}


.errorPage .button404 {
    margin-bottom: 10px;
}

.errorPage .wrap-row.noPadd  {
    margin-top: 0;
}