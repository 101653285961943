.users .add-user-btn {
    color: #000;
    border-color: #000;
}

.local .users .add-user-btn,
.proddisc .users .add-user-btn {
    color:              var(--color-dark)!important;
    border-color:       var(--color-dark)!important;
}

.users .add-user-btn:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
}

/* .local .users .add-user-btn:hover,
.proddisc .users .add-user-btn:hover {
    color:              var(--color-dark)!important;
    background-color:   var(--color-light)!important;
    border-color:       var(--color-dark)!important;
} */

/* .users .component-card, */
.users .component-options-wrapper {
    padding: 0px !important;
    margin: 5px 0px !important;
}

.USERS i.icon::before,
.users .add-user-btn i.icon::before {
    font-size: 17px;
}
 
/* .users .textGreen {
    color:  var(--color-green);
}
.users .textRed {
    color:  var(--color-red);
}
.users .textGrey{
    color: grey;
} */

.users .SimpleTable-wrapper.USERS {
    max-height: calc( 100vh - 244px );
    max-width:  calc( 100vw - 156px );
}

.users .gerarchiaTableWrapper table {
    border-spacing: 10px;
}

/* Tentativo di utilizzo font monospace, fallito perchè la colonna si allarga troppo */
/* .users td.DATA_INSERIMENTO {
    font-family:    'CourierPrime';
} */

.localsole .users .gerarchiaTableWrapper td,
.prodsole  .users .gerarchiaTableWrapper td {
    vertical-align: top;
    border: 2px solid var(--color-dark);
    border-radius: 10px;
}

.local .users .gerarchiaTableWrapper td,
.proddisc .users .gerarchiaTableWrapper td {
    vertical-align: top;
    border: 2px solid var(--color-dark);
    border-radius: 10px;
}

.users .gerarchiaTableWrapper .cellUser {
    display: flex;
    position: relative;
    padding: 5px;
}

.users .gerarchiaTableWrapper .cellUser span div {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users .gerarchiaTableWrapper .cellUser span div:nth-of-type(2){
    color: grey;
    font-size: 0.8em;
}

.users .gerarchiaTableWrapper .cellUser span{
    margin: 0px 5px !important;
}

.users .gerarchiaTableWrapper .wrapperCell {
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.users .gerarchiaTableWrapper .addUser,
.users .gerarchiaTableWrapper .removeUser {
    visibility: hidden;
}
.users .gerarchiaTableWrapper .cellUserButton{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.users .gerarchiaTableWrapper .cellUserButton button{
    padding: 4px !important;
}

.users .gerarchiaTableWrapper .wrapperCell:hover .addUser,
.users .gerarchiaTableWrapper td:not(.isRadix) .wrapperCell:hover .removeUser {
    visibility: visible;
}

.users .gerarchiaTableWrapper .removeUser {
    width: 20px;
    height: 20px;
}

.users .gerarchiaTableWrapper .activeFlag {
    font-size: 20px;
}

.users .gerarchiaTableWrapper .removeUser i.icon::before{
    font-size: 18px;
}

.localsole .users .gerarchiaTableWrapper tbody button:hover i.icon::before,
.prodsole  .users .gerarchiaTableWrapper tbody button:hover i.icon::before {
    color: var(--color-dark);
}

.local .users .gerarchiaTableWrapper tbody button:hover i.icon::before,
.proddisc .users .gerarchiaTableWrapper tbody button:hover i.icon::before {
    color: var(--color-dark);
}

.users .component-card .gerarchiaTableWrapper {
    margin-top: 10px;
    overflow: auto;
    max-height: calc(100vh - 273px);
    max-width: calc( 100vw - 70px );
}

.users .tabPanel {
    padding: 12px !important;
}

#demo-simple-select {
    min-width: 300px;
} 

.users .found {
    background-color: var(--color-rodyellow);
}

.users .gerarchia .searchFieldWrapper {
    margin: 0px 0px 10px 10px !important;
}

.users td.edit {
    padding-right: 0!important;
}

.users th.FLAG_COGNITO,
.users th.FLAG_ADMIN,
.users th.FLAG_ATTIVO {
    text-align: center;
}

.users td.DATA_INSERIMENTO,
.users td.DATA_ULTIMA_MODIFICA,
.users td.DATA_ULTIMA_LOGIN,
.users td.DATA_CANCELLAZIONE {
    text-align: right;
}

.users td.FLAG_COGNITO .iconWrap,
.users td.FLAG_ADMIN   .iconWrap,
.users td.FLAG_ATTIVO  .iconWrap {
    display:         flex;
    text-align:      center !important;
    justify-content: center;
}