.user-details-container{
    padding: 15px;
    height: 100%;
}

.user-details-container .boxes-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.user-details-container .boxes-container .box{
    background-color: #fff;
    width: 45%;
    margin: 15px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 5px;
    min-height: 50px;
}

.user-details-container .boxes-container .box .box-title{
    padding: 5px 15px 0px 15px;
    text-align: start;
}

.user-details-container .boxes-container .box .box-body{
    padding: 5px 15px 0px 15px;
    text-align: start;
} 

.user-details-container .back{
    background-color: #dddddd;
    color: #000000;
}

.user-details-container .back:hover{
    background-color: #adadad;
    color: #000000;
}