.forecast .table-wrapper-forecast{
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    text-align: start;
    width: fit-content;
}

.forecast .tabPanelPlacement .SimpleTable-wrapper {
    max-height: calc( 100vh - 300px );
    height: auto;
    max-width: calc( 100vw - 115px );
    /* overflow-y: hidden !important; */
}

.forecast .tabPanelAdUnit .SimpleTable-wrapper {
    max-height: calc( 100vh - 300px );
    height: auto;
    max-width: calc( 100vw - 115px );
    /* overflow-y: hidden !important; */
}

.forecast .tabPanel { 
    padding: 15px 10px !important;
}

.forecast .insAdjustmentGroupWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.forecast .insAdjustmentGroup {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.forecast .insAdjustmentGroupAdUnitTab {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
}

.forecast .selPlacementDateGroup,
.forecast .selPlacementGroup,
.forecast .selAdUnitGroup,
.forecast .insNewAdjustButtonGroup {
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.forecast .selPlacementGroup,
.forecast .selPlacementDateGroup > span,
/* .forecast .insNewAdjustButtonGroup, */
.forecast .insNewAdjustButtonGroup > span {
    margin-right: 5px;
}

.forecast .insNewAdjustButtonGroup > span {
    display: flex;
    flex-wrap: nowrap;
}



.forecast .selPlacementGroup > div,
.forecast .insNewAdjustButtonGroup > span > div {
    margin-right: 2px;
}

.forecast .selPlacementGroup .searchTypeBox {
    margin-left: 5px!important;
}

.forecast .selPlacementGroup > button {
    margin-right:0.5vw;
}

.forecast .insAdjustmentGroup > span > div > div > label,
.forecast .insAdjustmentGroupAdUnitTab > span > div > div > label {
    margin-top: -5px;
}

.forecast .insAdjustmentGroup > span > div > div > label.MuiInputLabel-shrink,
.forecast .insAdjustmentGroupAdUnitTab > span > div > div > label.MuiInputLabel-shrink {
    margin-top: 1px;
}

.forecast .insAdjustmentGroupAdUnitTab > span > div > div > div,
.forecast .insAdjustmentGroup > span > div > div > div {
    align-content: center;
    max-height: 42px !important;
    width: 45vw;
}

@media screen and (max-width: 1560px) {
    .forecast .insAdjustmentGroupAdUnitTab .insNewAdjustButtonGroup > span > div > div > div,
    .forecast .insAdjustmentGroup .insNewAdjustButtonGroup > span > div > div > div {
        width: 40vw !important;
    }
}

@media screen and (max-width: 1420px) {
    .forecast .insAdjustmentGroupAdUnitTab .insNewAdjustButtonGroup > span > div > div > div,
    .forecast .insAdjustmentGroup .insNewAdjustButtonGroup > span > div > div > div {
        width: 35vw !important;
    }
}

@media screen and (max-width: 1315px) {
    .forecast .insAdjustmentGroupAdUnitTab > span > div > div > div,
    .forecast .insAdjustmentGroup > span > span > div > div > div {
        width: 30vw !important;
    }
}
.forecast .TextFieldDate:hover {
    /* background-color:   var(--color-button); */
}
.forecast .TextFieldDate fieldset,
.forecast .TextFieldNum fieldset {
    border-color:       var(--color-dark) !important;
}

.forecast .TextFieldDate label.Mui-focused {
    color:              var(--color-dark) !important;
}

.forecast .insAdjustmentGroup .TextFieldDate > div,
.forecast .insAdjustmentGroupAdUnitTab .TextFieldDate > div,
.forecast .insAdjustmentGroup .TextFieldNum > div,
.forecast .insAdjustmentGroupAdUnitTab .TextFieldNum > div  {
    max-height: 42px !important;
    max-width: 115px !important;
    /* min-width: 145px !important; */
}

.forecast .insAdjustmentGroup .TextFieldDate > div,
.forecast .insAdjustmentGroupAdUnitTab .TextFieldDate > div {
    min-width: 160px !important;
    min-height: 55px !important;
}

.forecast .insAdjustmentGroup .TextFieldNum > div,
.forecast .insAdjustmentGroupAdUnitTab .TextFieldNum > div {
    min-width: 130px !important;
    min-height: 55px !important;
}

.forecast .AutocompleteFieldAdunit {
    margin-right: 15px !important;
}

.forecast .selectSign {
    min-width: 56px;
    max-height: 42px;
}

.forecast .selectValueType {
    min-width: 67px;
    max-height: 42px;
}

.forecast .selectSign.onFocus fieldset,
.forecast .selectValueType.onFocus fieldset {
    border-color: var(--color-dark) !important;
}

.forecast .blankSpan {
    min-width: 10px;
}

.forecast .insAdjustmentGroup .findAdjustForDate,
.forecast .insAdjustmentGroup .addNewAdjustButton,
.forecast .insAdjustmentGroupAdUnitTab .findAdjustForDate,
.forecast .insAdjustmentGroupAdUnitTab .addNewAdjustButton {
    color: #000;
    border: 1px solid #000;
    border-radius: 5px !important;
    text-decoration: none !important;
}

.forecast .addNewAdjustButton
,.forecast .findAdjustForDate {
    margin-left: 15px !important;
    height: 55px;
    width: 55px;
}

.forecast .insAdjustmentGroup .findAdjustForDate:hover,
.forecast .insAdjustmentGroup .addNewAdjustButton:hover,
.forecast .insAdjustmentGroupAdUnitTab .findAdjustForDate:hover,
.forecast .insAdjustmentGroupAdUnitTab .addNewAdjustButton:hover {
    background-color: var(--color-button);
    border-color: var(--color-dark);
}

.localsole.forecast .insAdjustmentGroup .findAdjustForDate:hover,
.localsole.forecast .insAdjustmentGroup .addNewAdjustButton:hover,
.localsole.forecast .insAdjustmentGroupAdUnitTab .findAdjustForDate:hover,
.localsole.forecast .insAdjustmentGroupAdUnitTab .addNewAdjustButton:hover,
.prodsole.forecast  .insAdjustmentGroup .findAdjustForDate:hover,
.prodsole.forecast  .insAdjustmentGroup .addNewAdjustButton:hover,
.prodsole.forecast  .insAdjustmentGroupAdUnitTab .findAdjustForDate:hover,
.prodsole.forecast  .insAdjustmentGroupAdUnitTab .addNewAdjustButton:hover {
    background-color: var(--color-button) !important;
    border-color: var(--color-dark) !important;
}

.forecast .graphButton:hover i.icon::before,
.forecast .insAdjustmentGroup .findAdjustForDate:hover i.icon::before,
.forecast .insAdjustmentGroup .addNewAdjustButton:hover i.icon::before,
.forecast .insAdjustmentGroupAdUnitTab .findAdjustForDate:hover i.icon::before,
.forecast .insAdjustmentGroupAdUnitTab .addNewAdjustButton:hover i.icon::before,
.forecast .svgIconForecast:hover i.icon::before {
    filter: drop-shadow(0px 0px 4px var(--color-shadow));
}

.forecast .svgIconForecast i.icon::before {
    cursor: pointer;
    padding-top: 3px;
    padding-left: 3px;
    height: 20px !important;
    width: 20px !important;
}

.forecast .insAdjustmentGroup .findAdjustForDate:disabled,
.forecast .insAdjustmentGroup .findAdjustForDate:disabled i.icon::before,
.forecast .insAdjustmentGroupAdUnitTab .findAdjustForDate:disabled,
.forecast .insAdjustmeinsAdjustmentGroupAdUnitTabntGroup .findAdjustForDate:disabled i.icon::before {
    color: rgba(0, 0, 0, 0.3);
    border-color: rgba(0, 0, 0, 0.3);
    filter: drop-shadow(0px 0px 0px 0.3px);
}

.forecast .invisible {
    display: none;
}

.forecast .graphButton {
    color: #000;
    border: 1px solid #000;
    text-decoration: none !important;
    height: 41px;
}

.forecast .graphButton:hover {
    background-color: #fff5ec3a;
    border-color: #000;
}

.forecast .graphButton.activedButton {
    background-color: #e6d5c4 !important;
}

.forecast .buttonGroup {
    position: absolute;
    top: 27px;
    right: 26px;
}

.forecast .grafico {
    height: calc(100vh - 300px);
}

.tablePopup td {
    min-width: 82px;
    padding: 3px 10px;
    max-width: 424px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.forecast ul.MuiList-padding li:hover {
    background-color: rgba(245, 245, 245) !important;
}

.forecast .searchTypeBox {
    flex-direction: row;
    align-items: center
}

.forecast .checkSearchType {
    padding: 4px!important;
}

/* .forecast .checkSearchType.MuiCheckbox-root.Mui-checked {
    color:            var(--color-dark);
}

.sole .forecast .checkSearchType.MuiCheckbox-root.Mui-checked {
    background-color: var(--color-main) !important;
}

.local .forecast .checkSearchType.MuiCheckbox-root.Mui-checked,
.dian .checkSearchType.MuiCheckbox-root.Mui-checked {
    background-color: var(--color-button) !important;
} */

.forecast .TextFieldDateSpan
,.forecast .TextFieldNum  {
    margin-right: 15px;
}

/* .AutocompleteFieldAdunit {    
    width: 100%;
    min-width: 700px;
} */