.formati .component-options-wrapper {
    /* padding-left: 0 !important; */
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.formati .SimpleTable-wrapper {
    max-height: calc( 100vh - 164px );
 /* max-width:  calc( 100vw - 115px ); */
}

 .formati .PESO
,.formati .FLAG_TEST {
    text-align:     center !important;
}
.formati .FLAG_TEST .icon {
     cursor:        default !important;
 }

.formati .modDesc input {
    padding:        4px;
    width:          306px;
}
.formati .modPeso input {
    padding:        4px;
    text-align:     center;
}

.hide {
    visibility:     hidden;
}

.formati .icon {
    cursor:         pointer;
}