@font-face {
  font-family:  'CourierPrime';
  src:          url('../assets/fonts/CourierPrime/CourierPrime-Regular.ttf') format('truetype');
}
@font-face {
  font-family:  'CourierPrime';
  src:          url('../assets/fonts/CourierPrime/CourierPrime-Bold.ttf') format('truetype');
  font-weight:  bold;
}
@font-face {
  font-family:  'CourierPrime';
  src:          url('../assets/fonts/CourierPrime/CourierPrime-BoldItalic.ttf') format('truetype');
  font-style:   italic;
  font-weight:  bold;
}
@font-face {
  font-family:  'CourierPrime';
  font-style:   italic;
  src:          url('../assets/fonts/CourierPrime/CourierPrime-Italic.ttf') format('truetype');
}
/*
@font-face {
  font-family:  'CourierPrime-Regular';
  src:          url('../assets/fonts/CourierPrime/CourierPrime-Regular.ttf') format('truetype');
}
@font-face {
  font-family:  'CourierPrime-Bold';
  src:          url('../assets/fonts/CourierPrime/CourierPrime-Bold.ttf') format('truetype');
  font-weight:  bold;
}
@font-face {
  font-family:  'CourierPrime-BoldItalic';
  src:          url('../assets/fonts/CourierPrime/CourierPrime-BoldItalic.ttf') format('truetype');
  font-style:   italic;
  font-weight:  bold;
}
@font-face {
  font-family:  'CourierPrime-Italic';
  font-style:   italic;
  src:          url('../assets/fonts/CourierPrime/CourierPrime-Italic.ttf') format('truetype');
}
*/
body {
  margin: 0;
  font-family: 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, 'Courier New', monospace;
}

html {
  height: 100%;
}

#root{
  height: 100%;
}

.textGreen {
    color: var(--color-green);
}

.textRed {
    color: var(--color-red);
}

.textGrey {
    color:  #494848;
}

.myExternalLinkwhite {
    text-decoration: underline !important;
    color: white;
}

.myExternalLinkID {
    text-decoration: none !important;
    color: black;
}

.myExternalLinkID:hover {
    text-decoration: underline !important;
}

.myExternalLinkIDUnderline {
    text-decoration: underline;
    color: black;
}

.myExternalLinkIDUnderline:hover {
    text-decoration: underline !important;
    font-weight:  bold;
}

td.NUM_LINE_ITEMS .link,
td.NUM_PLACEMENTS .link,
td.NUM_AD_UNITS .link,
td.NUM_CONTENDING_LINE_ITEMS .link,
td.CONTENDING_COUNT .link {
    text-decoration: underline !important;
}

.selectlabelOnFocus {
    color: var(--color-dark)!important;
}

.mybordercolor fieldset{
    border-color: var(--color-dark)!important;
}

.mybordercolor.onFocus fieldset {
    border-color: var(--color-dark) !important;
}

ul.MuiList-padding li.Mui-selected {
    background-color: var(--color-selectlabel) !important; /*  */
}

.flexySnackAlert {
    display:        flex;
    flex-direction: column;
}
.MuiTooltip-popper,
.MuiTooltip-tooltip {
    z-index: 11000 !important;
    font-size: 0.85rem!important;
    max-width: none!important;
    padding: 7px!important;
}

.hiddenElement {
    display: none;
}

.menu-notifiche .inactive {
    color: var(--color-darkgrey);
    background-color: var(--color-lightgrey);
}

.checkbox-custom {
    padding: 0px    !important;
}