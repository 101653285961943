.header {
    background-color: var(--color-header);
    color: black;
    min-height: 64px;
    padding: 0 40px;
    position: fixed;
    width: calc( 100vw - 135px );
    height: 0;
    left: 55px;
    z-index: 1000;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 19%);
}


.header-container{
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.side-menu-options,
.side-menu-logo {
    display: flex;
    align-items: center;
}
.user-settings-wrapper {
    display:        flex;
    align-items:    stretch;
    cursor:         pointer;
    border:         1px solid black!important;
    border-radius:  5px;
    padding:        10px;
}

.user-settings-wrapper i.icon::before {
    font-size: 20px;
}

.user-settings{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-left: 10px;
}

.app-name{
    font-size: 20px;
}

.main-content{
    display: flex;
    flex-direction: column;
    width: 100%;
}

aside{
    width: 60px;
    background-color: #1c1c19;
    color: #ffffff;
    text-align: left;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    height: 100%;
    transition: all 0.3s;
    z-index: 10000;
    box-shadow: 4px 0 4px 0 rgb(0 0 0 / 20%), 6px 0 10px 0 rgb(0 0 0 / 19%);
}

.logo-and-menu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.localsole .logo-and-menu-container .side-menu-logo > img,
.prodsole  .logo-and-menu-container .side-menu-logo > img {
    margin:             2px;
    margin-top:         24px;
    max-height:         18px;
    height:             18px;
    transition:         all 0.3s;
    z-index:            100001;
}

.local     .logo-and-menu-container .side-menu-logo > img.logoImage,
.demo      .logo-and-menu-container .side-menu-logo > img.logoImage,
.localdisc .logo-and-menu-container .side-menu-logo > img.logoImage,
.proddisc  .logo-and-menu-container .side-menu-logo > img.logoImage {
    margin:         10px 5px 5px 5px;
    max-width:      56px;
    max-height:     48px;
    height:         48PX;
    filter:
        drop-shadow(-1px -1px 0.5px #ffffffcc )
        drop-shadow( 1px -1px 0.5px #ffffffcc )
        drop-shadow(-1px  1px 0.5px #ffffffcc )
        drop-shadow( 1px  1px 0.5px #ffffffcc )
    ;
}

.local     .logo-and-menu-container .side-menu-logo > img.logoText,
.demo      .logo-and-menu-container .side-menu-logo > img.logoText,
.localdisc .logo-and-menu-container .side-menu-logo > img.logoText,
.proddisc  .logo-and-menu-container .side-menu-logo > img.logoText {
    position:           relative;
    margin-top:         10px;
    max-width:          100px;
    max-height:         48px;
    height:             48px;
    filter:
        drop-shadow(-1px -1px 1px #ffffffcc )
        drop-shadow( 1px -1px 1px #ffffffcc )
        drop-shadow(-1px  1px 1px #ffffffcc )
        drop-shadow( 1px  1px 1px #ffffffcc )
    ;
}

aside:hover{
    width:          166px;
}

.localsole aside:hover .logo-and-menu-container .side-menu-logo > img,
.prodsole  aside:hover .logo-and-menu-container .side-menu-logo > img {
    margin-top:     10px;
    margin-left:    10px;
    margin-right:   10px;
    max-height:     45px;
    height:         45px;
    /* width:         100%; */
}

aside ul{
    position: absolute;
    padding: 20px 0 20px 7px;
    top: 62px;
}

aside ul li{
    list-style: none;
    cursor: pointer;
    transition: color 0.3s;
    margin-bottom: 2px;
}

aside ul a{
    color: white;
    text-decoration: none;
}

.localsole aside ul a:hover,
.prodsole  aside ul a:hover {
    color: var(--color-main) !important;
}

.local aside ul a:hover,
.demo  aside ul a:hover,
.localdisc aside ul a:hover,
.proddisc  aside ul a:hover {
    color: var(--color-light) !important;
}

.dropdown-user-settings{
    width: 226px;
    background: white;
    height: 100%;
    z-index: 11;
    border-radius: 15px;
    text-align: left!important;
}

.logo-container{
    cursor: pointer;
    display: flex;
    width: 150px;
    align-items: center;
}
.logo-container:hover svg {
    filter:
            drop-shadow(-1px -1px 1px #ffffffb3 )
            drop-shadow( 1px -1px 1px #ffffffb3 )
            drop-shadow(-1px  1px 1px #ffffffb3 )
            drop-shadow( 1px  1px 1px #ffffffb3 )
            !important
    ;
}

.logo-wrapper{
    width: 100%;
    position: relative;
}

.logo-wrapper svg:nth-of-type(1){
    position: inherit;
    /* width: 135px; */
    filter: drop-shadow(0px 0px 1px #524f4f);
    z-index: 10000;
}
.logo-wrapper svg:nth-of-type(2){
    /* width: 135px; */
    filter:     brightness(100);
    position:   absolute;
    top:        1px;
    left:       1px;
    z-index:    100;
}
.logo-wrapper svg:nth-of-type(3){
    /* width: 135px; */
    filter:     brightness(100);
    position:   absolute;
    z-index:    100;
    bottom:     3px;
    right:      -1px;
}

.dropdown-user-settings .dropdown-user-options{
    background-color: #ffffff;
    transition:         background-color 0.3s;
    color:            #4d4d4d;
    justify-content:    flex-start;
    padding-left:       17px;
    width:              100%;
    height:             50px;
}
/*
.dropdown-user-settings .dropdown-user-options svg{
    color: #d4ab82!important;
} */

.dropdown-user-settings .dropdown-user-options:hover{
    background-color: #ddd;
}

.font-little{
    font-size: 12px !important
}

.logoRoobeek {
    width:  24px;
    height: 24px;
}

.roobeekPath {
    color:          #ffffff;
    text-decoration:  none;
}

.localsole .roobeekPath:hover,
.prodsole  .roobeekPath:hover {
    color: #e6d5c4 !important;
}

.local .roobeekPath:hover,
.demo  .roobeekPath:hover,
.localdisc .roobeekPath:hover,
.proddisc  .roobeekPath:hover {
    color: var(--color-light) !important;
}

.component-container{
    position: relative;
    height: 100%;
    padding: 10px;
    margin-top: 64px;
    margin-left: 55px;
    /* overflow: hidden; */
}

.component-container .component-card{
    background-color: #ffffff;
    border-radius: 5px;
    height: calc(100% - 30px);
    padding: 15px;
    overflow: hidden;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.component-container .component-options-wrapper{
    border-radius: 5px;
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: start;
    width: fit-content;
}

.MuiDayCalendar-weekContainer .Mui-selected,
.MuiPickersMonth-root         .Mui-selected,
.MuiPickersYear-root          .Mui-selected {
    background-color: var(--color-dark) !important;
}

.sole      .MuiDayCalendar-weekContainer .Mui-selected:hover,
.sole      .MuiPickersMonth-root         .Mui-selected:hover,
.sole      .MuiPickersYear-root          .Mui-selected:hover {
    background-color: var(--color-main) !important;
}

.local     .MuiDayCalendar-weekContainer .Mui-selected:hover,
.demo      .MuiDayCalendar-weekContainer .Mui-selected:hover,
.localdisc .MuiDayCalendar-weekContainer .Mui-selected:hover,
.dian      .MuiDayCalendar-weekContainer .Mui-selected:hover,
.local     .MuiPickersMonth-root         .Mui-selected:hover,
.demo      .MuiPickersMonth-root         .Mui-selected:hover,
.localdisc .MuiPickersMonth-root         .Mui-selected:hover,
.dian      .MuiPickersMonth-root         .Mui-selected:hover, 
.local     .MuiPickersYear-root          .Mui-selected:hover,
.demo      .MuiPickersYear-root          .Mui-selected:hover,
.localdisc .MuiPickersYear-root          .Mui-selected:hover,
.dian      .MuiPickersYear-root          .Mui-selected:hover {
    background-color: var(--color-shadow) !important;
}

.TextFieldDate p {
    position: inherit;
    right:    110px;
    width:    550px!important;
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
    min-height: 36px!important;
}

.MuiCheckbox-root.user-checked svg {
    border: 1px solid grey;
    border-radius: 5px;
    box-shadow: inset 0 0px 1px 2px var(--color-dark);
}

.MuiCheckbox-root.Mui-checked {
    color:            var(--color-dark)!important;
}

.MuiCheckbox-root:hover {
    background-color: var(--color-hovercheckbox) !important;
}

.dianButton {
    color: #000000 !important;
    border: 1px solid #000!important;
    border-radius: 5px !important;
    text-decoration: none !important;
    padding: 7px 25px !important;
    height: 42px;
    width: 42px;
}

.backButton .myIcon {
    font-size: 1.5rem;
}

.backButton {
    color: #000000 !important;
    border: 1px solid #000 !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    padding: 7px 25px !important;
    height: 42px;
    width: 175px;
}

.reloadButton {
    margin-left: 18px !important;
    color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 5px !important;
    text-decoration: none !important;
}

.fullViewButton,
.modeButton {
    color: #494848 !important;
    border: 1px solid #000 !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    width: 40px!important;
    min-width: 40px!important;
    padding: 6px 0px!important;
}

.localsole .fullViewButton:hover,
.localsole .modeButton:hover,
.localsole .backButton:hover,
.localsole .reloadButton:hover,
.prodsole  .fullViewButton:hover,
.prodsole  .modeButton:hover,
.prodsole  .backButton:hover,
.prodsole  .reloadButton:hover {
    background-color:   var(--color-button) !important;
    border-color:       var(--color-dark) !important;
}

.local    .fullViewButton:hover,
.local    .modeButton:hover,
.local    .backButton:hover,
.local    .reloadButton:hover,
.proddisc .fullViewButton:hover,
.proddisc .modeButton:hover,
.proddisc .backButton:hover,
.proddisc .reloadButton:hover {
    background-color:   var(--color-button) !important;
    border-color:       var(--color-dark) !important;
    color:              var(--color-dark)!important;
}


.link {
    text-decoration: none;
    color: #494848 !important;
}

.link,
.myShadow {
    display: inline-block;
}

.myShadow:hover span,
.link:hover i.icon::before,
.myShadow:hover i.icon::before {
    filter: drop-shadow(0px 0px 4px var(--color-shadow));
    cursor: pointer;
}

.dianButton:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
}

.dianButton .myIcon::before {
    font-size: 1.5rem!important;
}

.graphButton {
    color: #000!important;
    border: 1px solid #000!important;
    text-decoration: none !important;
    height: 41px;
}

.localsole .graphButton:hover,
.prodsole  .graphButton:hover {
    background-color:  var(--color-button);
    border-color: #000;
    transition: 0;
}

.local    .graphButton:hover,
.proddisc .graphButton:hover {
    background-color:  var(--color-lgtpinkgray);
    border-color: #000;
    transition: 0;
}

.localsole .graphButton.activedButton,
.prodsole  .graphButton.activedButton {
    background-color: var(--color-main) !important;
}

.local    .graphButton.activedButton,
.proddisc .graphButton.activedButton {
    background-color: var(--color-button) !important;
}

.graphButton,
.graphButtonLast {
    color: #000!important;
    border: 1px solid #000!important;
    text-decoration: none !important;
    height: 41px;
}

.localsole .graphButton:hover,
.localsole .graphButtonLast:hover,
.prodsole  .graphButton:hover,
.prodsole  .graphButtonLast:hover {
    background-color:  var(--color-button)!important;
    border-color: #000!important;
    transition: 0;
}

.local    .graphButton:hover,
.proddisc .graphButton:hover,
.local    .graphButtonLast:hover,
.proddisc .graphButtonLast:hover {
    background-color:  var(--color-lgtpinkgray)!important;
    border-color: #000!important;
    transition: 0;
}


.localsole .graphButton.activedButton,
.localsole .graphButtonLast.activedButton,
.prodsole  .graphButton.activedButton,
.prodsole  .graphButtonLast.activedButton {
    background-color: var(--color-main) !important;
}

.local    .graphButton.activedButton,
.local    .graphButtonLast.activedButton,
.proddisc .graphButton.activedButton,
.proddisc .graphButtonLast.activedButton {
    background-color: var(--color-button) !important;
}

.circularprogressWrapper {
    min-width: 37px;
    padding-top: 5px;
}

.circularprogressWrapper span,
.circularprogressWrapper svg {
    width: 25px !important;
    height: 25px !important;
    margin-right: 0.5vw;
    color: var(--color-dark) !important;
}

.centerText {
    padding: 8px;
}

.dialog-title-licenses {
    display:            flex;
    align-items:        center;
    justify-content:    flex-start;
    min-width:          346px;
    padding-bottom:     11px;
}

.dialog-title-licenses .icon {
    margin-right:       10px;
}


.licenses-label{
    white-space: normal;
    width: 385px;
    margin-bottom: 15px;
}

.licenses-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 8px 24px 0px 24px;
    color: #0000008a;
}
.licenses-table > div > div {
    display:     inline-block;
    height:      30px;
}
.licenses-table > div:nth-of-type(1) div {
    font-weight: bold;
}
.licenses-table > div > div:nth-of-type(1) {
    width:       200px;
}
.licenses-table > div > div:nth-of-type(2) {
    width:       50px;
    text-align:  right;
}

.myFlex {
    display:            flex;
    align-items:        center;
    justify-content:    center;
    flex-direction:     column;
    min-height:         98px;
    height:             100%;
}

.d-flex {
    display: flex;
    align-items: center;
}

/* .d-flex .icon::before {
    color: var(--color-dark);
    font-weight: bold;
} */

.form-group .noHover:hover,
.form-group .noHover:hover::before {
    cursor: default;
}

.d-flex.jumpDateIcon .icon:hover::before {
    color: black;
    cursor: pointer;
}

.pm-0 {
    padding: 0!important;
    margin: 0!important;
    background-color: white;
}

.numberHover:hover{
    font-weight: bold;
    cursor: pointer;
    /* filter: drop-shadow(0px 0px 2px var(--color-shadow)); */
}

td.zero {
    color:      var(--color-lightgrey);
    text-decoration: none!important;
}

/* .cols-groups th {
    position:           -webkit-sticky!important;
    position:           sticky!important;
    background-color:   white;
    z-index:            101;
    top:              0px!important;
} */

.sticky1 {
    position:           -webkit-sticky!important;
    position:           sticky!important;
    background-color:   white;
    z-index:            100;
    right:              0px!important;
}

.sticky2 {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            100;
    right:              49px;
}

.sticky2alt {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            99;
    right:              78px;
}

.sticky3 {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            100;
    right:              127px;
}

.sticky4 {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            100;
    right:              205px;
}

.sticky3alm {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            100;
    right:              104px;
}

.sticky5 {
    position:           -webkit-sticky;
    position:           sticky;
    background-color:   white;
    z-index:            100;
    left:               64px !important;
}

th.sticky1,
th.sticky2,
th.sticky3,
th.sticky3alm,
th.sticky4,
th.sticky5 {
    z-index:            101!important;
}

.Mui-focused{
    color:  rgba(0, 0, 0, 0.8)!important;
}

.hiddenButton {
    visibility: hidden;
    min-width: 24px;
}

.smallSpace {
    margin-right: 10px !important;
}

.mediumSpace {
    margin-right: 25px !important;
}

.bigSpace {
    margin-right: 3rem !important;
}

::-webkit-scrollbar{
    width:0.8em;
    height:0.8em;
    background: var(--color-ultralightgrey);
    margin-right: 0px!important;
}

::-webkit-scrollbar:hover{
    background-color:var(--color-ultralightgrey);
    border: none;
}

::-webkit-resizer{
    -webkit-border-radius:4px;
    background-color: var(--color-ultralightgrey);
    border: none;
}

::-webkit-scrollbar-thumb{
    min-height:0.8em;
    min-width:0.8em;
    -webkit-border-radius:4px;
    background-color: var(--color-header);
    border: none;
}

::-webkit-scrollbar-thumb:hover{
    background-color: var(--color-header);
    border: none;
}

::-webkit-scrollbar-thumb:active{
    background-color: var(--color-header);
    border: none;
}

.green-dot {
    position: absolute;
    top: 2px;
    left: 3px;
    width: 0.50rem;
    height: 0.50rem;
    border: 1px solid gray;
    background-color:   lime;
    border-radius: 50%;
}


/* CAMBIAMENTI PER LINEA PACING, PERCENTAGE */
.percentage {
    border:         0.10rem solid #918989;
    border-radius:  3px;
    text-align:     center;
    color:        black;
    padding:        7px;
    margin-bottom:  0.6rem;
    margin-top:     0.6rem;
}

.percentagePacing {
    border:     0.1rem solid;
    position:   absolute;
    height:     70%;
    top:        15%;
} 

.myBold {
    font-weight: bold;
}

.menu-notifiche .read {
    color:            var(--color-darkgrey);
    background-color: var(--color-lightgrey);
}

.info-icon {
    position: absolute;
    top:      12px;
    right:    12px;
    display:  flex;
}

.info-icon .link-pdf {
    color:  #474747!important;
}

.text-grey {
    color: #606060;
}

th.ALERTS ,
th.FLAG_VERIFICATO,
th.MENU_ITEMS {
    text-align:      center;
}

th.ALERTS div,
th.FLAG_VERIFICATO div,
th.MENU_ITEMS div {
    display: inline-flex !important;
    vertical-align: middle;
}

td.ALERTS ,
td.FLAG_VERIFICATO,
td.MENU_ITEMS {
    padding: 0px    !important;
    text-align:      center;
}

li .side-menu-options {
    /* max-height: 42px; */
    min-height: 42px;
    min-width: 31px;
    /* max-width: 31px; */
    padding: 0px 0px 2px 8px;
}

li .side-menu-options.selected-menu-option {
    border: 1px solid white;
    border-radius: 5px;
}

li .side-menu-options.selected-menu-option:hover {
    border: 1px solid var(--color-header)!important;
    border-radius: 5px;
}

li .side-menu-options span {
    width: 100px;
    max-width: 100px;
    height: 100%;
    align-content: center;
}

li .side-menu-options i.icon {
    padding-top: 2px;
}

.selector-date .MuiButtonBase-root .MuiChip-label {
    font-family: 'Courier New', Courier, monospace !important;
    font-weight: bold!important;
}

.MuiAutocomplete-popper li .selector-date-option {
    font-family: 'Courier New', Courier, monospace !important;
    font-weight: bold!important;
}

.selector-date .MuiChip-label::first-letter,
.MuiAutocomplete-popper li .selector-date-option::first-letter {
    text-transform: capitalize;
}

.MuiAutocomplete-popper li.MuiAutocomplete-option[aria-selected="true"] {
    /* background-color: var(--color-light)!important; */
    background-color: white!important;
}

.MuiAutocomplete-popper li.MuiAutocomplete-option[aria-selected="true"]:hover{
    background-color: var(--color-selectlabel)!important;
}

.MuiPaper-root .MuiAlert-message a {
    color: white!important;
}