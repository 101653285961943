/* @media screen and (max-width: 1065px) { 

} */

:root {
    --alarm-settings-popup-width: 650px;
    --alarm-settings-popup-users-width: 1010px;
}

.alarmDetail .titleAllarmeDettaglio {
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    /* max-width: calc( 100vw - 560px ); */
    min-width: fit-content;
}

.allarmi     .textGreen,
.alarmDetail .textGreen {
    color:      var(--color-green);
}

.allarmi     .textRed,
.alarmDetail .textRed {
    color:      var(--color-red);
}

.allarmi .enabled {
    color:      var(--color-green);
}

.allarmi .disabled {
    color:      var(--color-lightgrey);
}

/* Class Abort
.allarmi .enabled {
    color: grey;
}
.allarmi .disabled {
    color: grey;
}
*/

.allarmi       .NUM_ELEM,
.alarmDetail td.BEGIN_DATE,
.alarmDetail td.END_DATE,
.alarmDetail td.DATA_RIF {
    text-align: right !important;
}

.allarmi .FLAG_ENABLED,
.allarmi .FLAG_EMAIL,
.allarmi .FLAG_RECIPIENT_EMAIL,
.allarmi .FLAG_AGENT_EMAIL,
.allarmi .LAST_EXECUTION {
    text-align:     center !important;
}

.alarmDetail   .FLAG_DOWNTREND div,
.alarmDetail td.LINE_ITEM_ID:not(.unique) div,
.alarmDetail td.PLACEMENT_ID:not(.unique) div,
.alarmDetail td.AD_UNIT_ID:not(.unique) div,
.alarmDetail td.FLAG_DOWNTREND:not(.unique) div {
    display:        flex;
    text-align:     center !important;
    justify-content: center;
}

.allarmi td.LAST_EXECUTION {
    text-align: right !important;
}
.allarmi td.ARROW {
    text-align: right !important;
}

.allarmi td.ID_ALERT .link {
    display:        flex;
    text-align: right !important;
    justify-content: flex-end;
}

.alarmDetail .STATUS,
.alarmDetail .PRIORITY,
.alarmDetail .LINE_ITEM_TYPE {
    text-align: center !important;
}

.alarmDetail th.BEGIN_DATE,
.alarmDetail th.END_DATE {
    text-align: center;
}

.allarmi     .link,
.alarmDetail .link {
    text-decoration: none;
    color: #494848 !important;
}


.alarmDetail .myShadow:hover div,
.alarmDetail .myShadow:hover span {
    filter: drop-shadow(0px 0px 4px var(--color-shadow));
}

.allarmi .link i.icon::before{
    padding-top: 1px;
}

.alarmDetail td.LINE_ITEM_ID:not(.unique),
.alarmDetail td.PLACEMENT_ID:not(.unique) {
    padding: 1px !important;
}

.allarmi .SimpleTable-wrapper {
    overflow-y: hidden !important;
}

.allarmi .SimpleTable-wrapper th,
.allarmi .SimpleTable-wrapper td {
    cursor: default;
}
/* 
.alarmDetail .backButton .myIcon {
    font-size: 1.5rem;
}

.alarmDetail .backButton {
    color: #000000 !important;
    border: 1px solid #000;
    border-radius: 5px !important;
    text-decoration: none !important;
    padding: 7px 25px !important;
    height: 42px;
    width: 175px;
}

.alarmDetail .reloadButton {
    margin-left: 18px;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px !important;
    text-decoration: none !important;
}

.localsole .alarmDetail .backButton:hover,
.localsole .alarmDetail .reloadButton:hover,
.prodsole  .alarmDetail .backButton:hover,
.prodsole  .alarmDetail .reloadButton:hover {
    background-color:   var(--color-button);
    border-color:       var(--color-dark);
}

.local    .alarmDetail .backButton:hover,
.local    .alarmDetail .reloadButton:hover,
.proddisc .alarmDetail .backButton:hover,
.proddisc .alarmDetail .reloadButton:hover {
    background-color:   var(--color-button);
    border-color:       var(--color-dark);
    color:              var(--color-dark)!important;
} */

.component-container .component-options-wrapper-alarmDetail {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.component-container .component-options-wrapper-title {
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.component-container .component-options-wrapper-alarmNoRecord {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 15px 15px 15px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.alarmDetail .titleAllarmeDettaglio{
    font-size: 1.6em;
    margin: 0px 25px;
}

.alarmDetail .subTitleAllarmeDettaglio{
    font-size: 1em;
    padding-top: 8px;
}

.alarmDetail .SimpleTable-wrapper {
    max-height: calc( 100vh - 186px );
    max-width: calc( 100vw - 115px );
}

.alarmDetail .optionTab {
    min-width: fit-content;
    display: flex;
    flex-direction: row;
}

.alarmDetail .optionTab > div,
.alarmDetail .optionTab > span {
    margin: 0px 7px 0px 0px;
}

.alarmDetail .optionTab > div > div {
    height: 41px;
}

.alarmDetail .TextFieldNum {
    max-width: 115px;
}

.alarmDetail .TextFieldDate {
    display: flex;
    max-width: 170px;
    height: 42px!important;
}

.alarmDetail .TextField9 {
    display: flex;
    max-width: 200px;
    height: 42px!important;
}

.alarmDetail .TextFieldDate > div {
    max-width: 195px;
    max-height: 42px;
    /* padding-top: 1px; */
}

.alarmDetail .TextFieldNum:hover {
    border-radius: 5px;
    background-color: var(--color-button)!important;

}

.alarmDetail .TextFieldDate fieldset,
.alarmDetail .TextFieldNum fieldset {
    border-color: var(--color-dark) !important;
}
.alarmDetail .TextFieldNum label.Mui-focused,
.alarmDetail .TextFieldDate label.Mui-focused {
    color: var(--color-dark) !important;
}

.alarmDetail tr:nth-of-type(2) th.LINE_ITEM_ID:not(.unique) div,
.alarmDetail tr:nth-of-type(2) th.AD_UNIT_ID:not(.unique) div,
.alarmDetail tr:nth-of-type(2) th.PLACEMENT_ID:not(.unique) div {
    visibility: hidden;
}

.alarmDetail .grafico {
    height: calc(100vh - 176px);
}

.alarmDetail .OutlinedInputNum {
    /* padding-right: 2px !important; */
    width: 105px;
}

.alarmDetail .OutlinedInputNum > div {
    padding-right: 5px !important;
}

.alarmDetail .OutlinedInputNum > div > input {
    padding-left: 15px;
    /* padding-right: 5px; */
}

/* .alarmDetail td.NUM_PLACEMENTS,
.alarmDetail td.NUM_AD_UNITS   {
    text-decoration: underline;
} */

.alarmDetail .iconButtonPadding {
    background-color: var(--color-light) !important;
    padding: 4px!important;
}

.alarmDetail .SimpleTable.FLAG_VERIFICATO .VIEWABILITY_TARGET.number  .MuiFormControl-root {
    margin: 0px;
}


.alarmDetail .fsAllarmi {
    font-size: 0.95rem;
    margin-left: 20px;
    border-radius: 5px;
    display: inline-flex;
    height: 30px!important;
    align-items: center;
}

.alarmDetail .msAllarmi {
    margin-left: 3px;
}
.alarmDetail .hrAllarmi {
    font-weight: bold;
}

.alarmDetail .contendingCount {
    font-size: 12px;
    position: absolute;
    right: 34px;
    top: 15px;
    font-weight: bold;
}

.set-alarm-dialog .dialog-content {
    padding-top:        0;
    padding-bottom:     0;
}

.set-alarm-dialog .subtitle {
    font-size:          14px;
}
/*
.set-alarm-dialog .RECIPIENT_EMAIL {
    width:              calc( var(--alarm-settings-popup-width) - 50px );
}
*/
 .set-alarm-dialog .PARAM_VALUES
,.set-alarm-dialog .EMAIL_PARAMS
 {
    margin-top:         5px;
    margin-bottom:      5px;
    position:           relative;
    border:             1px solid lightgrey;
    border-radius:      5px;
    padding:            14px;
    width:              calc( var(--alarm-settings-popup-width) - 50px - 30px );
}

.set-alarm-dialog form > div:not(:last-of-type) {
    margin-bottom:      20px;
 }

.set-alarm-dialog .PARAM_VALUES .param-field {
    margin-top:         10px;
    margin-right:       10px;
}

 .set-alarm-dialog .PARAM_VALUES .pImpDeliv
,.set-alarm-dialog .PARAM_VALUES .pErrorsPerc
,.set-alarm-dialog .PARAM_VALUES .pDays {
    width:              100px;
}
 .set-alarm-dialog .PARAM_VALUES .pDaysBefore
,.set-alarm-dialog .PARAM_VALUES .pDaysAfter {
    width:              140px;
}
.set-alarm-dialog .PARAM_VALUES .pStatusList  {
    display:            none;
    width:              550px;
}

 .set-alarm-dialog .PARAM_VALUES .pMMFastDays
,.set-alarm-dialog .PARAM_VALUES .pMMSlowDays
,.set-alarm-dialog .PARAM_VALUES .pImpDelivPerc {
    width:              120px;
}
.set-alarm-dialog .PARAM_VALUES .pMMFastPendDays {
    width:              170px;
}
 .set-alarm-dialog .PARAM_VALUES .pPlacementId
,.set-alarm-dialog .PARAM_VALUES .pDaysGraph {
    display:            none;
}

.set-alarm-dialog .numSelected {
    background-color:   lightgreen !important;
}

.set-alarm-dialog .dayBox {
    height:             40px;
    width:              44px;
    margin-right:       5px;
    margin-bottom:      5px;
    cursor:             pointer;
}
.set-alarm-dialog .titolo {
    font-weight:        bold;
    font-size:          14px;
}

.set-alarm-dialog .sottotitolo {
    display:            block;
    font-size:          12px;
    margin-bottom:      10px;
    color:              rgb(105, 105, 105);
}

.set-alarm-dialog .giorni-scelti {
    display:            flex;
    flex-direction:     row;
    flex-wrap:          nowrap;
    align-content:      center;
    align-items:        center;
}

 .set-alarm-dialog > div > div
,.set-alarm-dialog > div > div > div {
    max-width:          var(--alarm-settings-popup-width) !important;
}
 .set-alarm-dialog.dialog-users > div > div
,.set-alarm-dialog.dialog-users > div > div > div {
    max-width:          var(--alarm-settings-popup-users-width) !important;
    /* max-height:         calc(100% - 58px); */
}

.fa-gear.alarm-settings {
    color:              #727272;
}

 .set-alarm-dialog .PARAM_VALUES::before
,.set-alarm-dialog .EMAIL_PARAMS::before
 {
    content:            attr(data-title);
    font-size:          13px;
    color:              #575757;
    font-variant:       small-caps;
    position:           absolute;
    top:                -11px;
    left:               4px;
    padding:            0 4px 0 4px;
    background-color:   white;
}

.set-alarm-dialog .FLAG_AGENT_EMAIL {
    padding-bottom:     10px;
}
.set-alarm-dialog .FLAG_RECIPIENT_EMAIL {
    padding-bottom:     10px;
}
.set-alarm-dialog .RECIPIENT_EMAIL > input {
    margin-bottom:      10px;
}

 .set-alarm-dialog .regroupEmail input
,.set-alarm-dialog .regroupEmail label
,.set-alarm-dialog .FLAG_AGENT_EMAIL input
,.set-alarm-dialog .FLAG_AGENT_EMAIL label
,.set-alarm-dialog .FLAG_RECIPIENT_EMAIL input
,.set-alarm-dialog .FLAG_RECIPIENT_EMAIL label
{
    cursor:             pointer;
}

.set-alarm-dialog .addEmailButton {
    cursor:             pointer;
    font-size:          16px;
    background-color:   #b4b4b4;
    color:              white;   
}

.set-alarm-dialog .addEmailButton:hover {
    background-color:   grey;
}

.dialog-users th.FLAG_ADMIN,
.dialog-users th.FLAG_TRAFFICKER,
.dialog-users th.FLAG_ATTIVO {
    text-align: center;
}
.dialog-users td.check {
    text-align:      center;
}

.dialog-users .USERS td.FLAG_ATTIVO,
.dialog-users .USERS td.FLAG_ADMIN,
.dialog-users .USERS td.FLAG_TRAFFICKER {
    cursor: default !important;
}

.dialog-users td.FLAG_ADMIN        .iconWrap,
.dialog-users td.FLAG_TRAFFICKER   .iconWrap,
.dialog-users td.FLAG_ATTIVO       .iconWrap {
    display:         flex;
    text-align:      center !important;
    justify-content: center;
}

.user-disabled td {
    color: #a53434;
}

.dialog-users .SimpleTable-wrapper.EMAIL-LIST {
    max-height: calc( 100vh - 195px );
    height: auto;
}
/* th.FLAG_VERIFICATO {
    text-align:      center;
} */
/* th.FLAG_VERIFICATO div{
    display: inline-flex !important;
    vertical-align: middle;
} */

/*td.ALERTS ,
td.FLAG_VERIFICATO {
    padding: 0px    !important;
    text-align:      center;
} */
