.middlePage-Loader{
    position:fixed;
    top:calc(50% - 40px);
    left:calc(50% - 40px);
}

.circularProgress{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 1;
}

.localsole .circularProgress span,
.prodsole .circularProgress span{
    width: 40px !important;
    height: 40px !important;
    color: var(--color-main) !important;
}

.local .circularProgress span,
.localdisc .circularProgress span,
.proddisc .circularProgress span{
    width: 40px !important;
    height: 40px !important;
    color: var(--color-shadow) !important;
}