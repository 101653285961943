.dashboard .reloadButton {
    height: 54px;
    width:  54px;
    margin-left: 18px;
    color: #000;
    border: 1px solid #000;
    border-radius: 10px !important;
    text-decoration: none !important;
}

.localsole .dashboard .reloadButton:hover,
.prodsole  .dashboard .reloadButton:hover {
    background-color:   var(--color-button);
    border-color:       var(--color-dark); 
}

.local    .dashboard .reloadButton:hover,
.proddisc .dashboard .reloadButton:hover {
    background-color:   var(--color-button);
    border-color:       var(--color-dark);
    color:              var(--color-dark)!important;
}
/* 
.dashboard .graphButton {
    color: #000;
    border: 1px solid #000;
    text-decoration: none !important;
    height: 41px;
}

.localsole .dashboard .graphButton:hover,
.prodsole  .dashboard .graphButton:hover {
    background-color:  var(--color-button);
    border-color: #000;
    transition: 0;
}

.local    .dashboard .graphButton:hover,
.proddisc .dashboard .graphButton:hover {
    background-color:  var(--color-lgtpinkgray);
    border-color: #000;
    transition: 0;
}

.localsole .dashboard .graphButton.activedButton,
.prodsole  .dashboard .graphButton.activedButton {
    background-color: var(--color-main) !important;
}

.local    .dashboard .graphButton.activedButton,
.proddisc .dashboard .graphButton.activedButton {
    background-color: var(--color-button) !important;
} */


.dashboard .myShadow:hover div,
.dashboard .myShadow:hover span {
    filter: drop-shadow(0px 0px 4px var(--color-shadow));
}

.dashboard .link i.icon::before{
    padding-top: 1px;
}

ul.MuiList-padding li.Mui-selected {
    min-height: 36px;
}

.dashboard .mybordercolor {
    min-width: 250px;
    max-height: 54px;
}
