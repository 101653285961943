.upload .upload-section {
    border: 2px dotted gray;
    width: 30%;
    /* margin: auto; */
    /* margin-top: 10%; */
    padding: 40px;
    text-align: center;
    cursor: pointer;
}
.upload .component-options-wrapper {
    width: 100%!important;
} 