.paginationButtonGroup {
    font-size:          13px;
    vertical-align:     middle              !important;
    padding-right:      7px;
}

.paginationButtonGroup button {
    cursor:             pointer;
    color:              #7d8083                     !important;
    background-color:   white                       !important;
    margin:             2px                           !important;
    padding:            0                             !important;
    font-size:          12px                          !important;
    text-align:         center                        !important;
    border:             1px solid var(--color-header) !important;
    border-radius:      5px                           !important;
    min-height:         22px                          !important;
    height:             30px                          !important;
    min-width:          100px                         !important;
    width:              100px                         !important;
    line-height:        20px                          !important;
}

.paginationButtonGroup button.paginaCorrente {
 /* color:              #7d8083                 !important;
    background-color:   #e1ebf3                 !important; */
    background-color: var(--color-light)         !important;
    border-color:     var(--color-lightgrey) !important;
}

.paginationButtonGroup button:hover {
/* color:             var(--color-main)        !important; */
    background-color:  var(--color-pagination)      !important;
    border-color:      var(--color-header)        !important;
}
   