.flexy {
    display:                flex;
    align-items:            center;
    justify-content:        center;
    height: 100%;
}

.change-password .app-login-container {
    border-radius: 5px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}
.flex-column{
    display: flex;
    flex-direction: column;
}

.change-password-container .submit-btn-wrapper{
    display: flex;
    justify-content: center;
}

.change-password-container .submit-btn{
    text-decoration: none;
    color: #000;
    border: 1px solid #000;
    padding: 10px;
    width: 100%; 
}

.change-password-container .submit-btn:hover{
    background-color: var(--color-button);
    border: 1px solid var(--color-dark);
}

.submit-btn:disabled{
    color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.change-password-container .app-login-content-noAuth{
    padding:   35px 35px 35px;
    max-width: 300px;
    width:     300px;
    display:   flex;
    position:  relative;
    justify-content: center;

}

.change-password .app-login-main-content {
    align-items: center;
}

.change-password .withMargin {
    margin-bottom: 27px;
}

.change-password .with-min-margin {
    margin-bottom: 10px;
}
