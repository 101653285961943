.adjustment .component-wrapper-Adj-AdUnit {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0!important;
} 

.adjustment .tabPanel {
    padding: 6px!important
}

.adjustment .insAdjColumnHeaderWrapper {
    display: flex;
    flex-direction: column;
    width: 100%
}

.adjustment .insAdjustmentHeaderGroupWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
}

.adjustment .insAdjustOptionGroup {
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.adjustment .smallHeight .MuiInputBase-root {
    height:  42px;
}

.adjustment .dianButton.smallHeight {
    height:     42px!important;
    width:      42px!important;
    padding:    0!important;
}

.adjustment .AutocompleteFieldAdunit .MuiInputLabel-root[data-shrink="false"] {
    top:-5px
}

.adjustment .AutocompleteFieldAdunit .MuiInputBase-root {
    display: flex;
    align-content: center;
    align-items: center;
}
.adjustment .AutocompleteFieldAdunit {    
    width: 600px;
}

.adjustment .smallSpace {
    margin-right: 10px
}

.adjustment .bigSpace {
    margin-right: 3rem!important;
}

.adjustment .AutocompleteFieldAdunit input {
    margin-left: 2px;
}

.adjustment .TextFieldDateSpan input {
    width:      90px!important;
}

.adjustment .TextFieldNumAdj input {
    width:      110px!important;
}

.adjustment .table-wrapper-adjustment {
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    text-align: start;
    width: fit-content;
}

.adjustment .SimpleTable-wrapper {
    max-height: calc( 100vh - 262px );
    height: auto;
    max-width: calc( 100vw - 115px );
}

.popupWrapperContentAdjustmentEdit .tablePopup {
    margin-bottom:  20px;
}

.popupWrapperContentAdjustmentEdit .TextFieldEtichetta {
    width:          100%;
    margin-bottom:  10px;
}

.popupWrapperContentAdjustmentEdit .TextFieldNumAdj {
    width:          477px;
}

.popupWrapperContentAdjustmentEdit .selValue {
    width:          70px;
}

.adjustment .flyButton {
    position: absolute;
    top:      -3px;
}

.adjustment td.dettaglio {
    display:         flex;
    justify-content: center;
}

.adjustment tr:nth-of-type(2) th.dettaglio:not(.unique) div {
    visibility: hidden;
}

.adjustment .backButton:hover {
    background-color:   var(--color-button)!important;
    border-color:       var(--color-dark)!important;
}