.App {
  height: 100%;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.auth-background{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-background{
  height:           100%;
  background-color: var(--color-light)!important;
  display:          flex;
}

.dp-container{
  margin-left: 15px;
  width: 600px;
}

.dp-container,.dropBox{
  height: 100px;
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-pointer.underlined:hover{
  text-decoration: underline;
}

.cursor-default{
  cursor: default;
}

button{
  border: none;
}

.loading-background{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-lgtpinkgray);
  z-index: 9999999999;
  overflow: hidden;
}

.helperText {
    margin-top:             0px !important;
    margin-bottom:          10px !important;
    height:                 17px !important;
    font-size:              11px !important;
    color:                  var(--color-red)!important;
}

.criteriPass {
    display:                inline-block;
    font-size:              11px !important;
    text-align:             left;
}

.left-message {
    display:                inline-block;
    text-align:             left;
}
/* .MuiTooltip-popper {
    max-width: 1000px;
} */