i.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px!important;
    height: 24px!important;
}

.icon.textGreen {
    color:  var(--color-green)!important;
}

.icon.textRed {
    color:  var(--color-red)!important;
}

.icon.textDark {    
    color: var(--color-dark);
    font-weight: bold;
}

.icon.infoIcon {
    color:  var(--color-lightgrey)!important;
}

.icon.infoIcon:hover {
    color:             var(--color-darkgrey)!important;
    filter:
              drop-shadow(-1px -1px 1px var(--color-ultralightgrey) )
              drop-shadow( 1px -1px 1px var(--color-ultralightgrey) )
              drop-shadow(-1px  1px 1px var(--color-ultralightgrey) )
              drop-shadow( 1px  1px 1px var(--color-ultralightgrey) )
    ;
}

.icon.textGrey {
    color:  var(--color-darkgrey)!important;
}

.icon.textLightGrey {
    color:  var(--color-lightgrey)!important;
}

.icon.textBlack {
    color:  black!important;
}

.icon::before {
    font-size: 20px;
}

.icon.bigIcon::before {
    font-size: 25px!important;
}

.icon.gargantulaIcon::before {
    font-size: 28px!important;
}

.testIcon       td div,
.anagraphDetail td.FOGLIA      .iconWrap,
.users          td.FLAG_ADMIN  .iconWrap,
.users          td.FLAG_ATTIVO .iconWrap {
    display:         flex;
    text-align:      center !important;
    justify-content: center;
}

.icon.user-checked {
    border: 1px solid grey;
    border-radius: 5px;
    box-shadow: inset 0 0px 1px 2px grey;
}

.testIcon .component-container .component-options-wrapper  {
    margin: 0;
}

.testIcon .SimpleTable-wrapper {
    max-height: calc( 100vh - 154px );
    /* max-width: calc( 100vw - 115px ); */
}

.testIcon th.ICON {
    text-align: center;
}