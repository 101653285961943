.sell-or-not .flexyTitle,
.sell-or-not .dettaglioDescription {
    margin-left: 5px;
}
.sell-or-not .component-card {
    height:  calc(100% - 20px) !important;
    padding: 10px !important;
}

.sell-or-not .component-head-options-wrapper,
.sell-or-not .options-buttons-wrapper {
    display:         flex;
    justify-content: space-between;
    flex-direction:  row;
    align-items:     center;
    position:        relative;
}

.sell-or-not .MuiFormGroup-row label {
    margin-right: 25px;
}

.sell-or-not .group-detail-buttons-wrapper {
    display:         flex;
    justify-content: flex-end;
    flex-direction:  row;
    align-items:     center;
    position:        relative;
}

.sell-or-not .reloadButton {
    margin-right: 5px;
    padding: 7px!important
}

.sell-or-not .component-wrapper {
    height:         calc(100% - 62px);
    display:        flex;
    flex-direction: row;
}

.sell-or-not .sellOrNot-selector-wrapper {
    width:        24%;
    margin-right: 5px;
    width:        400px;
}

.sell-or-not .sellOrNot-detail-wrapper {
    width:  76%;
    height: calc(100vh - 146px);
    width:  calc(100vw - 497px);
}

.sell-or-not .sellOrNot-detail-wrapper.full {
    width:  100%!important;
}

.sell-or-not .graph-wrapper,
.sell-or-not .table-wrapper,
.sell-or-not .sellOrNotNoRecord-wrapper {
    border-radius: 5px;
    box-shadow:    0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    padding:       5px;
}

.sell-or-not .table-wrapper.selector-placement {
    margin-top: 5px;
}

.sell-or-not .sellOrNot-detail-wrapper .graph-wrapper {
    margin-bottom: 5px;
}

.sell-or-not .table-wrapper.selector-sito .SellOrNot-Selector-Sito {
    max-height: calc(100vh - 156px);
}

.sell-or-not .table-wrapper.selector-placement .SellOrNot-Selector-Placement {
    min-height: calc(100vh - 245px);
    max-height: calc(100vh - 245px);
}

.sell-or-not .sellOrNot-detail-wrapper .graph-wrapper{
    height:     calc(50% - 13px);
    position: relative;
}

.sell-or-not .sellOrNot-detail-wrapper .table-wrapper.detail-line-items {
    height:     calc(50% - 12px);
    position: relative;
}

.sell-or-not .sellOrNot-detail-wrapper .sellOrNotNoRecord-wrapper {
    height:     calc(100% - 12px);
}

.sell-or-not .table-wrapper.detail-line-items .SellOrNot-Detail {
    min-height: calc((100vh - 258px)/2);
    max-height: calc((100vh - 258px)/2);
    margin-top: 4px;
}

.sell-or-not .sellOrNot-detail-wrapper .graph-wrapper.full-graph,
.sell-or-not .sellOrNot-detail-wrapper .table-wrapper.full-table {
    height: calc(100vh - 156px) !important;
}

.sell-or-not .table-wrapper.detail-line-items.full-table .SellOrNot-Detail {
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    width: 100%;
}

.sell-or-not .table-wrapper.detail-line-items.full-table .SellOrNot-Detail table {
    position: relative;
    width: inherit;
}

.sell-or-not .table-wrapper.detail-line-items.full-table .SellOrNot-Detail tr {
    width: 100%;
}

.sell-or-not .SimpleTable-wrapper {
    width: 100%!important;
}

.sell-or-not .grafico-bar-stacked {
    height: calc(100% - 5px);
}

.sell-or-not .component-wrapper .MuiRadio-root {
    padding: 0;
}


.sell-or-not td {
    min-height: 44px!important;
}

.sell-or-not td .no-data,
.MuiAutocomplete-popper .mancano-tutti-giorni {
    color: #d32f2f !important;
}

.MuiAutocomplete-popper .mancano-giorni {
    color: #dd8d14 !important;
}

.sell-or-not td.LINE_ITEM_TYPE,
.sell-or-not th.BEGIN_DATE,
.sell-or-not th.END_DATE,
.sell-or-not th.LINE_ITEM_TYPE,
.sell-or-not th.VENDUTO,
.sell-or-not th.REVENUE,
.sell-or-not th.IMPRESSIONS_SCHEDULED {
    text-align: center;
}

.sell-or-not td.BEGIN_DATE,
.sell-or-not td.END_DATE {
    text-align: right;
}

.sell-or-not .full-table th.LINE_ITEM_NAME,
.sell-or-not .full-table td.LINE_ITEM_NAME {
    
    min-width: 100%!important;
    width:     100%!important;
    max-width: 100%!important;
}

.sell-or-not .selector-date input {
    min-width: 0!important;
}

.sell-or-not .selector-date .MuiInputBase-root,
.sell-or-not .group-option-wrapper .selector-line .MuiSelect-select {
    padding: 7px 29px 6px 8px!important;
}

.sell-or-not .mybordercolor .MuiFormLabel-root[data-shrink="false"] {
    top: -10px!important;
}

.sell-or-not .selector-date,
.sell-or-not .group-detail-buttons-wrapper .selector-line {
    margin-right: 5px;
}

.sell-or-not .options-buttons-wrapper .selector-date {
    position: absolute;
    top: 0;
    right: 45px;
    background-color: white;
    z-index: 999;
}

.sell-or-not .toggle-dettaglio {
    cursor: pointer!important;
}