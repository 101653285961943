.input-field.flag-admin{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myFlexCreateUser{
    display: flex;
    align-items: center;
}

.withPadding {
    padding-top: 10px;
}

.change-password .login-credentials-wrapper {
    margin-top:             0px;
    margin-bottom:          10px;
    width:                  100%;
}


/* 
.change-password .MuiCheckbox-root.Mui-checked {
    color:            var(--color-dark);
}

.sole .change-password .MuiCheckbox-root:hover {
    background-color: var(--color-main) !important;
}

.local .change-password .MuiCheckbox-root:hover,
.dian .change-password .MuiCheckbox-root:hover {
    background-color: var(--color-button) !important;
} */
