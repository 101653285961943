.agentsTraffickers .component-options-wrapper {
    padding-left:   0 !important;
    padding-right:  0 !important;
    padding-bottom: 0 !important;
    margin-bottom:  0 !important;
}

.agentsTraffickers .SimpleTable-wrapper {
    max-height:     calc( 100vh - 176px );
    max-width:      calc( 100vw - 112px );
}

.agentsTraffickers .bold {
    font-weight:    bold;
}

.agentsTraffickers .PESO {
    text-align:     right !important;
}

.agentsTraffickers tr:nth-of-type(2) th.PESO {
    padding-right:  20px;
}

.agentsTraffickers td.PESO {
    padding-right:  20px;
}

.agentsTraffickers .peso-per-mese {
    text-align:     center;
}
 .agentsTraffickers .peso-per-mese .isCurrent span
,.agentsTraffickers .peso-per-mese span.isCurrent {
    font-weight:    bold;
}


.agentsTraffickers   .peso-per-mese .red                { color: #d60000; }
.agentsTraffickers   .peso-per-mese .green              { color: green;   }
.agentsTraffickers   .peso-per-mese .grey               { color: grey;    }
.agentsTraffickers td.peso-per-mese i.icon              { cursor: default; }
.agentsTraffickers td.peso-per-mese i.icon::before      { font-size: 15px !important; }
.agentsTraffickers td.peso-per-mese span:first-of-type  { display: inline-block; width: 20px; }
.agentsTraffickers td.peso-per-mese span:last-of-type   { display: inline-block; width: 26px; text-align: right; }

 .agentsTraffickers .ingroup.Traffickers:nth-of-type(even)
,.agentsTraffickers .cell.Traffickers:nth-of-type(even)
{
    box-shadow: inset 0 0 0 43px rgb(202 202 202 / 19%);
}

.agentsTraffickers .ingroup.Peso.per.mese:nth-of-type(even)
{
    box-shadow: inset 0 0 0 43px rgb(230 213 196 / 19%);
}

/* Soluzione custom per individuare il primo elemento (versione potenziata di primo figlio/first child) che possiede una specifica classe */
/* non la usiamo perchè più dinamico individuarlo nel .js */
/* .agentsTraffickers th:not(.Traffickers) + .Traffickers,
   .agentsTraffickers td:not(.Traffickers) + .Traffickers */
.agentsTraffickers .ingroup.Traffickers.firstCol {
    border-left:  1px solid #5e5e5e;
}

.agentsTraffickers .ingroup.Traffickers.lastCol {
    border-right: 1px solid #5e5e5e!important;
}

.agentsTraffickers .ingroup.Peso.per.mese.firstCol {
    border-left:  1px solid #ddc4a9;
}

/* .agentsTraffickers .ingroup.Peso.per.mese:last-child */
.agentsTraffickers .ingroup.Peso.per.mese.lastCol {
    border-right: 1px solid #ddc4a9;
}

.agentsTraffickers .ingroup.Traffickers
{
    white-space: normal !important;
}

.agentsTraffickers .ingroup.Traffickers
,.agentsTraffickers .cell.Traffickers
{
    text-align: center;
}

.agentsTraffickers .colgroup.TRAFFICKER_ID,
.agentsTraffickers .colgroup.Peso.per.mese { /* scritto proprio così .Peso.per.mese */
    font-size:                  16px;
    font-weight:                600;
    letter-spacing:             1px;
    text-align:                 center;
 /* border:                     1px solid #e3e3e3; */
    border-top-left-radius:     15px;
    border-top-right-radius:    15px;
}
.agentsTraffickers .colgroup.TRAFFICKER_ID {
    box-shadow:                 inset 0 0 0 43px rgb(202 202 202 / 19%);
    border-top:   1px solid #5e5e5e;
    border-left:  1px solid #5e5e5e;
    border-right: 1px solid #5e5e5e; 
}

.agentsTraffickers .colgroup.Peso.per.mese {
    box-shadow:                 inset 0 0 0 43px rgb(230 213 196 / 19%);
    border-top:   1px solid #ddc4a9;
    border-left:  1px solid #ddc4a9;
    border-right: 1px solid #ddc4a9; 
}

.agentsTraffickers th.cell.Traffickers.TRAFFICKER_ID {
    line-height:                20px;
}

.agentsTraffickers .disattivo {
    color:                      #d60000;
}

.agentsTraffickers .traffickerNameHeader {
    font-weight:                bold;
}

.agentsTraffickers .pesoTotHeader {
    color:                      black !important;
}

.agentsTraffickers  .diffPesoMedioHeader.zero      { color: lightgrey !important; }
.agentsTraffickers  .diffPesoMedioHeader.positive  { color: #c80000 !important; }
.agentsTraffickers  .diffPesoMedioHeader.negative  { color: #008700 !important; }

.agentsTraffickers .non-assegnato {
    color:                      rgb(150,150,150) !important;
}

.agentsTraffickers .auto-btn {
    color:                  #000;
    border-color:           #000;
}

.agentsTraffickers .auto-btn:hover {
    background-color:       var(--color-button)!important;
    border-color:           var(--color-dark)!important;
}
