.graph.fullView {
    height:             calc( 100vh - 168px );
}

.graph .group-option-wrapper {
    display:         flex;
    justify-content: space-between;
    margin-top:      2px;
    margin-bottom:   4px;
}
.graph .selector-line {
    margin-top:     1px;
}

.graph .modeButton,
.graph .selector-line {
    margin-right: 5px;
}

.graph-tooltip {
    table-layout:       fixed;
    background-color:   white;
    border:             1px solid lightgray;
    padding:            5px;
    max-width:          200px;
    border-radius:      2px;
}

.graph-tooltip-row td {
    word-wrap:          break-word;
    padding:       2px !important;
}

.graph-tooltip tr:not(:last-of-type) td {
    border-bottom: 1px solid lightgray !important;
}

.graph-tooltip .graph-tooltip-row span {
    min-width:          200px !important;
    width:              200px;
}

.graph-tooltip .graph-tooltip-row  .graph-tooltip-label div,
.graph-tooltip .graph-tooltip-row  .graph-tooltip-value div {
    min-width:          90px !important;
    width:              90px;
}

.graph.grafico > div > div > svg > g > g:nth-of-type(4) > g > text {
    transform:          rotate(-45deg) translate(100px, 0) ;
}

.graph .graph-tooltip-value div {   
    text-align: end;
}
.graph .graph-tooltip-value {
    font-weight:        bold;
    align-content:      flex-end;
    font-size:          13px;
}
.graph .graph-tooltip-label {
    font-size:          13px;
}

.graph svg > g > g:nth-of-type(n+4) > text {
    font-size:          16px !important;
    font-weight:        bold !important;
    fill:               #383838 !important; 
    /* text-shadow:        -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.graph svg > g > text {
    /* 
        HOW TO TARGET TEXT TOTALS IN BAR
    */
}
